import ReportGen from '@/assets/ReportGen.js';
export default {
	name: 'accountStatementMixin',
	props: {},
	data() {},
	methods: {
		async printAccountStatement(club, pin) {
			try {
				// let data = await axios.get(
				// 	`https://api.shootpro.cloud/club/${club}/accountstatement/${pin}`
				// );
				// let asdata = data.data;
				// let blob = await ReportGen.getReport('/AccountStatement.docx', asdata);
				let blob = await ReportGen.getCannedReport(
					`/account-statement/club/${club}/pin/${pin}`
				);
				return blob;
			} catch (err) {
				console.error(err);
				return 'ERROR';
			}
		},
	},
	computed: {},
};

<template>
	<v-container fluid>
		<v-row justify="center">
			<v-col xs="12" sm="8" md="6" lg="4">
				<v-alert
					v-model="warn"
					type="warning"
					dismissible
					icon="mdi-alert-outline"
					transition="fab"
				>
					The Club or Pin Code is incorrect. Please try again.
				</v-alert>
			</v-col>
		</v-row>
		<v-row justify="center">
			<v-col xs="12" sm="8" md="6" lg="4">
				<v-card class="pa-4">
					<div class="text-h6 text-center">Select your Club</div>
					<v-autocomplete
						v-model="Club"
						prepend-icon="mdi-map-marker"
						class="full-opacity"
						:items="clubs"
						item-text="ClubName"
						item-value="ClubId"
					/>
					<div class="text-h6 text-center mt-6">Enter your Pin Code</div>
					<v-text-field
						v-model="pin"
						prepend-icon="mdi-pin"
						v-mask="mask"
						placeholder="1ABCD-2EF3"
						@keyup="upperCase"
						@keyup.enter="getAndPrint"
						clearable
					/>
					<v-card-actions>
						<v-btn
							block
							color="primary"
							:loading="loading"
							:disabled="!pin || pin.length < 9"
							@click="getAndPrint"
						>
							<v-icon class="mr-2">mdi-printer</v-icon>
							Get my Statement
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
		<ScreenDialog v-show="ShowModal" v-model="ShowModal">
			<template #Title> View Statement </template>
			<div>
				<iframe
					:src="blobURL"
					width="100%"
					height="100%"
					style="min-height: 92vh !important"
				/>
			</div>
		</ScreenDialog>
	</v-container>
</template>

<script>
import axios from 'axios';
import accountStatementMixin from '@/assets/Statement.js';
import ScreenDialog from './ScreenDialog.vue';

import { isMobile } from 'mobile-device-detect';
import { saveAs } from 'file-saver';

isMobile;
export default {
	components: { ScreenDialog },
	mixins: [accountStatementMixin],
	data() {
		return {
			e1: 1,
			Club: null,
			clubs: [],
			pin: '',
			mask: '-NNNN',
			ShowModal: false,
			blobURL: '',
			loading: false,
			warn: false,
		};
	},
	mounted() {
		axios.get('https://api.shootpro.cloud/public/club').then(({ data }) => {
			this.clubs = data;
			this.Club = this.clubs[0].ClubId;
			if(localStorage.lastKnownClubStatement){
				if(data.map(x=>x.ClubId).includes(localStorage.lastKnownClubStatement)){
					this.Club = localStorage.lastKnownClubStatement
				}
			}
		});
	},
	watch: {
		pin(v) {
			if (!v) this.mask = '';
			else if (v.length <= 4) this.mask = '-NNNN';
			else if (v && v.length == 10) this.mask = 'NNNNN-NNNN';
			else this.mask = new Array(v.length - 5).fill('N').join('') + '-NNNN';
		}
	},
	methods: {
		async getAndPrint() {
			this.loading = true;
			this.warn = false;
			if (this.Club && this.pin) {
				localStorage.lastKnownClubStatement = this.Club;
				let statementBlob = await this.printAccountStatement(
					this.Club,
					this.pin
				);
				if (statementBlob != 'ERROR') {
					if (isMobile) {
						saveAs(statementBlob, 'statement.pdf');
					} else {
						this.blobURL = window.URL.createObjectURL(statementBlob);
						this.ShowModal = true;
					}
				} else {
					this.warn = true;
				}
			} else {
				this.warn = true;
			}
			this.loading = false;
			this.pin = null;
		},
		upperCase() {
			if (this.pin) this.pin = this.pin.toUpperCase();
		},
	},
};
</script>

<style scoped></style>

<template>
	<v-app>
		<v-app-bar id="hdrBar" app color="#202225" dark flat class="mx-auto">
			<v-toolbar-title>
				<div v-if="$vuetify.breakpoint.smAndDown">
					<v-spacer />
					<v-img
						src="https://www.club-beta.shootpro.cloud/spclogo-white.webp"
						:max-height="`${headerHeight - 15}px`"
						contain
					/>
				</div>
			</v-toolbar-title>
			<v-spacer />
			<v-img
				v-show="$vuetify.breakpoint.mdAndUp"
				src="https://www.club-beta.shootpro.cloud/spclogo-white.webp"
				:aspect-ratio="3.45"
				max-height="45px"
				max-width="300px"
				contain
			/>
			<v-spacer />
		</v-app-bar>

		<v-main>
			<HelloWorld />
		</v-main>
	</v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';

export default {
	name: 'App',

	components: {
		HelloWorld,
	},

	data: () => ({
		//
	}),
	computed: {
		headerHeight() {
			return this.$vuetify.breakpoint.smAndDown ? 56 : 64;
		},
	},
};
</script>
<style>
/*Set background image to fill background */
.v-application--wrap {
	background-image: url('https://www.club-beta.shootpro.cloud/TrapMachineMed.webp');
	background-size: cover;
	background-position: center;
}
</style>

import axios from 'axios';
import dayjs from 'dayjs';

let URL = 'https://reports.shootpro.cloud';
// let URL = 'http://localhost:9999';

//Failsafe
if(!window.location.href.includes('localhost') && URL.includes('localhost'))
 URL = 'https://reports.shootpro.cloud';

let f = {
	/**
	 * Gets a report from the reportgen server
	 * @param {string} TemplateURL
	 * @param {object} bodyObj
	 * @param {string} type (optional) PDF, DOCX, ODT
	 */
	// eslint-disable-next-line
	async getReport(TemplateURL, bodyObj, type = 'PDF') {
		try {
			// let URL = "https://localhost:9999/";
			let blob = await getTemplate(TemplateURL);

			let data = new FormData();

			let templateBlob = new Blob([blob]);

			bodyObj.TimeGen = dayjs().format('MMM D YYYY h:mm:ss a');
			let sdata = JSON.stringify(bodyObj);
			data.append('data', sdata);
			data.append('convertTo', type);
			data.append('template', templateBlob, 'file.docx');

			let config = {
				mode: 'no-cors',
				header: {
					'Access-Control-Allow-Origin': '*',
					'Content-Type': `multipart/form-data`,
				},
				credentials: 'same-origin',
				responseType: 'arraybuffer',
			};

			try {
				let response = await axios.post(URL, data, config);
				let blob = new Blob([new Uint8Array(response.data)], {
					type: 'application/pdf',
				});
				return blob;
			} catch (error) {
				console.log('error processing report', error);
				if (error.response) console.log(error.response, error.response.body);
				return undefined;
			}
		} catch (error) {
			console.log('error getting template', error);
			if (error.response) console.log(error.response);
			return undefined;
		}
	},
	async getCannedReport(reportPath) {
		try {
			let response = await axios({
				url: `${URL}/reports${reportPath}`,
				responseType: 'blob', // important
			});
			let blob = new Blob([response.data], { type: 'application/pdf' });
			return blob;
		} catch (error) {
			console.error('error getting canned report', error);
			if (error.response) console.error(error.response);
			throw error
		}
	},
};

async function getTemplate(TemplateURL) {
	return axios({
		url: TemplateURL,
		method: 'get',
		responseType: 'blob',
		headers: {
			responseType: 'blob',
		},
	}).then((response) => response.data);
}

export default f;
